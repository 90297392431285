@import "constants";
@import "server.tables";

.bold-svg {
  stroke-width: 2;
}

.bigger {
  font-size: 1.2rem;
}

button.calendar, button.calendar:active {
  width: 2.75rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=') !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

bi[name="x"] {
  cursor: pointer;
}

.dragged-modal {
  height: auto;
  top: 2%;
}

.modal-windows {
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 576px) {
    max-width: 500px;
    &-sm {
      max-width: 300px;
    }
  }
  @media (min-width: 992px) {
    &-lg,
    &-xl {
      max-width: 800px;
    }
  }
  @media (min-width: 1200px) {
    &-xl {
      max-width: 1140px;
    }
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #272b30;
  opacity: .3;
  z-index: 1500;
}

ngb-timepicker input {
  width: 100% !important;
}
